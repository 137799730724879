function Footer() {
  return (
    <footer>
      <p className="font-light">
        Copyright 2024 LocoMocoSec, All Rights Reserved.
      </p>
    </footer>
  );
}

export default Footer;
